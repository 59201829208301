
import Vue from 'vue';
import Component from 'vue-class-component';
import moment from 'moment';
import ProjectStatus from '@/pages/project/components/project-status.vue';
import ListFilter from '@/pages/project/components/list-filter.vue';
import { Socket } from 'vue-socket.io-extended';
import { PROJECT_STATUS, SOCKET_EVENTS, SURVEY_STATUS, USER_LEVEL } from '@/enum/enums';
import { Pagination } from '@/interface/interface';

interface Docs {
  bookmark: boolean,
  endDate: string,
  groupId: string,
  manager: string,
  my: boolean,
  show: { [key in SURVEY_STATUS]: boolean },
  startDate: string,
  status: PROJECT_STATUS,
  summary: { [key in SURVEY_STATUS]: number },
  surveyId: string,
  surveyNo: number,
  title: string,
}

type ActivateStatus = 'ACTIVATE' | 'CLOSED';

@Component({
  components: {
    ListFilter,
    ProjectStatus,
  },
})
export default class AdminSendNotification extends Vue {
  private page = 1;
  private totalPages = 0;
  private totalDocs = 0;
  private limit = 10;
  private projectStatus: ActivateStatus = 'ACTIVATE';
  private list: Docs[] = [];

  created() {
    this.load();
  }

  async sendLaunchConfirmation() {
    console.log('sendLaunchConfirmation');
    // TODO: sendLaunchConfirmation
  }

  async sendInvoiceNotification(surveyId: string) {
    console.log('sendInvoiceNotification')
    // TODO: sendInvoiceNotification
    const { result, data, message } = await this.axios({
      url: `/billing/invoice-notification/${surveyId}`,
      method: 'post',
    });
    console.log(result, data, message);
  }

  async setDisabled(surveyId: string) {
    this.loading = true;
    try {
      const { result, data, message } = await this.axios.get('/project');

      console.log(surveyId);
      if (!result) throw message;

      console.log(data);

    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  async load() {
    this.loading = true;
    try {
      const {
        page,
        status,
      } = this.$route.query as { [key: string]: string };

      if (page) this.page = parseInt(page, 10);

      if (!this.projectStartDate && !this.projectEndDate) {
        const { data: { startDate, endDate } } = await this.axios.get(`project/default-date`);
        this.projectStartDate = startDate;
        this.projectEndDate = endDate;
      }

      if (status) this.projectStatus = status as ActivateStatus;

      const params: { [key: string]: string | number } = {
        startDate: this.projectStartDate,
        endDate: this.projectEndDate,
        projectStatus: this.projectStatus,
        page: this.page,
        limit: this.limit,
      };


      const { result, data, message } = await this.axios.get<Pagination<Docs>>('project', { params });

      if (!result) throw message;
      else {
        const { docs, page, totalPages, totalDocs } = data;
        this.page = page;
        this.list = docs;
        this.totalPages = totalPages;
        this.totalDocs = totalDocs;
      }
    } catch (e) {
      this.validationMessages(e);
    }
    this.loading = false;
  }

  makeFilter(page = '1') {
    const { projectStatus: status } = this;
    const dateFilter = `&startDate=${ this.projectStartDate }&endDate=${ this.projectEndDate }`;
    const statusFilter = `&status=${ status }`;

    return `?page=${ page }${ dateFilter }${ statusFilter }`;
  }

  filterSubmit() {
    const query = this.makeFilter();
    this.$router.push(query).catch(() => ({}));
  }

  linkGen(page: string) {
    return this.makeFilter(page);
  }

  dateFormat(date: string) {
    return moment(date).format('MM/DD/YYYY');
  }

  toSpecific(status: PROJECT_STATUS, surveyId: string) {
    return status === PROJECT_STATUS.TEMP ? `/project/registration/${ surveyId }` : `/project/created/home/${ surveyId }?${ this.makeFilter(this.page.toString()) }`;
  }

  get PROJECT_STATUS() {
    return PROJECT_STATUS;
  }

  @Socket(SOCKET_EVENTS.SURVEY_ROUTE_IN)
  routeIn(data: any) {
    console.log(data);
  }

  @Socket(SOCKET_EVENTS.SURVEY_ROUTE_OUT)
  async routeOut(data: { surveyId: string, status: SURVEY_STATUS }) {
    const { surveyId, status } = data;
    console.log(data);
    const index = this.list.findIndex((l) => l.surveyId === surveyId);
    if (index > -1) {
      const { summary, show } = this.list[index];
      switch (status) {
        case SURVEY_STATUS.COMPLETE:
          summary.COMPLETE += 1;
          break;
        case SURVEY_STATUS.TERMINATE:
          summary.TERMINATE += 1;
          break;
        case SURVEY_STATUS.QUOTA_FULL:
          summary.QUOTA_FULL += 1;
          break;
      }
      show[status] = false;
      await this.$nextTick();
      show[status] = true;
    }
  }

  isProcessing(status: PROJECT_STATUS) {
    return PROJECT_STATUS.ON === status;
  }

  statusEditable(my: boolean, status: PROJECT_STATUS) {
    switch (this.getUserLevel) {
      case USER_LEVEL.USER: {
        const { TEMP, CREATED, CANCEL } = PROJECT_STATUS;
        return my && ![TEMP, CREATED, CANCEL].includes(status);
      }
      case USER_LEVEL.PMI_USER: {
        return my;
      }
      default: {
        return true;
      }
    }
  }
}

